.pagevisual {
    @include responsive("tablet"){
        padding-top: calc(#{$header_height} + 10rem);
        padding-bottom: 10rem;
    }
    @include responsive("pc"){
        padding-top: calc(#{$header_height} + 11.5rem);
        padding-bottom: 11.5rem;        

    }

    position: relative;
    z-index: 0;
    margin: 0;

    // 背景画像
    // background-image: url("../img/mainvisual.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $color_navy_light;

    // container
    &__container {
        @include container("default");
        position: relative;
        z-index: 2;
        padding-top: 0;
        padding-bottom: 0;
        @include responsive("tablet"){
            padding-left:15px;
            padding-right:15px;
        }
        padding-left:0;
        padding-right:0;
    }
    // text
    &__text-main {
        @include font_design();

        position: absolute;
        top: calc(#{$header_height} + 30%);
        padding-left:15px;

        z-index: 2;
        @include responsive("tablet"){
            position:inherit;
            top:0;
    
        }

        @include responsive("pc"){
            position:inherit;
            top:0;
            font-size: 3.6rem;        
        }
        font-size: 3.2rem;
        color:$color_navy;
        // &:after {
        //     display: block;
        //     max-width: 20rem;
        //     margin: 1.6rem 0rem;
        //     // border-bottom: 3px solid $color_black;
        //     background: $color_black;
        //     content: "";
        // }
    }
    &__text-sub {
        color: $color_black;
    }
    &__image{
    // 背景画像に半透明のレイヤーを被せる
    &::before {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: rgba($color_white, 0.5);
        content: "";
    }
        @include responsive("tablet"){
            &::before{
                display: none;
            }
            max-width: 70%;
            width:70%;
            position: absolute;
            right:0;
            top:-4rem;
            aspect-ratio: 7/3;
            overflow: hidden;     
            min-height: 230px;  
            max-height:230px;
            img{
                width:100%;
            }
        }
        @include responsive("pc"){
            top:-6rem;
            aspect-ratio: 8/3;
            max-height: inherit;
        }

    }
}
