// top-news
.top-news {
    // container
    &__container {
        @include container("default");
        @include responsive("pc") {
            padding-bottom:3.4rem;
            &::after{
                content: "";
                float: none;
                clear: both;
                display: block;
            }
        }
    }
    // heading
    &__heading {

        margin-bottom: 6.4rem;
        text-align: center;
        @include responsive("pc") {
            padding-top:2rem;
            float: left;
            width:300px;
            text-align: left;
        }
    }
    // list
    &__list {
        display: block;
        padding: 0;
        margin: 0 auto;
        max-width: 100rem;

        @include responsive("pc") {
            min-height:200px;
            float: right;
            width:calc(100% - 300px);
        
        }
    }
    &__list-item {
        display: block;
        margin:0;
        padding:0;
        position: relative;
        // &:not(:first-child){
            margin-top: 1.6rem;
            padding-bottom: 1.6rem;
            border-bottom: 1px solid #ddd;
            &::after{
                position: absolute;
                bottom: -1px;
                width:20%;
                content: "";
                height:1px;
                background-color: $color_navy;
            }
        // }
    }
    // guide
    &__guide {
        padding-top: 6.4rem;
        @include responsive("pc") {
            padding:0;
            float: left;
            width:300px;
            // clear: both;
        }
    }
}
