// news-detail
.news-detail {
    // container
    &__container {
        @include container("default");
        padding-top:0;
        padding-bottom:0;
        max-width: 96rem;
        margin: 0 auto 4.8rem auto;
        border-bottom:1px dashed #ccc;
    }
    // title
    &__title {
        margin-bottom: 4.8rem;
        font-weight: 700;
        font-size: 3.2rem;
    }
    // image
    &__image {
        margin-bottom: 4.8rem;
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    // body
    &__body {
        @include responsive("pc") {
            padding: 3.6rem;
        }
        margin-bottom: 4.8rem;
        background: #ffffff;
    }
    // button
    &__button {
        @include responsive("pc") {
            padding-top: 6.4rem;
        }
        padding-top: 3.2rem;
    }
}
