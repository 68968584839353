// container
@mixin container($type: "default") {
    @if $type == "default" {
        position: relative;
        max-width: $content_width;
        margin: 0 auto;
        padding: 12.4rem 15px;
    } @else if $type == "mainvisual" {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: $content_width;
        height: 80vh;
        padding-top:6rem;
        margin: 0 auto;
        text-align: center;
    } @else if $type == "breadcrumbs" {
        position: relative;
        max-width: $content_width;
        margin: 0 auto;
        padding: .8rem 15px;
    } @else if $type == "contact-guide" {
        @include shadow("contact-guide");
        max-width: $content_width;
        margin: 0 auto;
        padding: 6.4rem 15px;
        background: $color_light;
    } @else if $type == "footer" {
        max-width: $content_width;
        margin: 0 auto;
    }
}