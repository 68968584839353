// works-list
.works-list {
    // container
    &__container {
        @include container("default");
    }
    // list
    &__list {
        margin: 0 -15px 0 -15px;
        @media (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__list-item {
        margin: 15px 15px 4.8rem 15px;
        @media (min-width: 992px) {
            width: calc(100% / 3 - 30px);
            margin: 15px;
        }
    }
    // pagination
    &__pagination {
        padding-top: 6.4rem;
    }
}
