// visually hidden
@mixin visually_hidden() {
    display: block !important;
    visibility: visible !important;
    opacity: 0 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 4px !important;
    height: 4px !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: none !important;
}


.is-force-hidden{
    display: none !important;
}