.page-heading {

    $content: #{&}__content;

    &__content {
        margin: 0;
        padding: 0;
        &--left{
            text-align:left;
        }
        &--center{
            text-align:center;
        }
        &--right{
            text-align:right;
        }
    }
    // メインテキスト
    &__text-main {
        @include font_design();
        font-size: 3.0rem;
        &:after {
            display: block;
            max-width: 240px;
            padding-top:2.4rem;
            // border-bottom: 3px solid $color_black;
            content: "";
        }
        // contentのModifierに応じて
        // 疑似要素のスタイルを変更する
        #{$content}--left &::after{
            margin-left:0;
            margin-right:auto;
        }
        #{$content}--center &::after{
            margin-left:auto;
            margin-right:auto;
        }
        #{$content}--right &::after{
            margin-left:auto;
            margin-right:0;
        }
    }
    // サブテキスト
    &__text-sub {
        font-weight: 400;
        font-size: 1.6rem;
        color: $color_black;
        padding-top: 2.4rem;
    }
    // メッセージ
    &__message {
        max-width: 800px;
        margin: 0 auto;
        padding-top: 6.4rem;

        //margin-bottom: 6.4rem;
        color: $color_grey;
        font-weight: 400;
        font-size: 1.6rem;
        text-align: center;
    }
}
