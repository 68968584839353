.page-block{
    &__center{
    }
    &__container{
        &.is-bg1{
            background-image: url(../img/image07.jpg);
            background-size: cover;
            color:$color_white;
        }
        // &.is-bg2{
        //     background-image: url(../img/members.jpg);
        //     background-size: cover;
        //     color:$color_white;
        // }

        padding:3rem;
        @include responsive("pc") {
            padding:8rem;
        }
        
    }
    &__inner{
        @include responsive("tablet") {
        width: 60%;
        margin:0 auto;
        padding-top:1rem;
        padding-bottom:1rem;
        }
    }
    &__title{
        text-align: center;
        font-size:2.6rem;
        margin-bottom:2rem;
    }
    &__subtext{
        min-height:30vmin;
        &-center{
  
           text-align: center;
     
        }
    }
}

.tel-link{
    font-size:3rem;
    font-weight: bold;
    color:$color_navy;
    &:before{
       content:"";
       background-image: url(../img/icon-tel.svg); 
       background-size: contain;
       background-repeat: no-repeat;
       margin-right:1rem;
       height:2.25rem;
       width:2.25rem;
       display: inline-block;
    }
}