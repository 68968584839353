.swiper-container {
    overflow: hidden; //両橋見切れにする場合に記述
    // width: 750px; //希望のスライダー横幅
    width:100%;
    height: 500px; //希望のスライダー高さ
    margin: 0 auto;
    position:relative;
    background-color: $color_navy_light;
    // &::after{
    //     background: red;
    //     content: "";
    //     width: 100%;
    //     height:50%;
    //     position: absolute;
    //     z-index: 0;
    //     bottom:0;
    //   }
    .swiper {
      width: 100%;
      height: 100%;
      position:relative;
      padding-bottom: 100px; //スライダーコントロールを下部に表示させるための余白を設置する場合
      max-width: 600px !important; //両橋見切れにする場合の中心スライダー横幅
      overflow: visible !important; //両橋見切れにする場合orスライダーコントロールを下部に表示させるに記述

      .swiper-wrapper {
        width: 100%;
        height: 100%;
        transition-timing-function: linear; //スライドを流し続ける場合
        .swiper-slide {
          padding-right: 20px; //両橋見切れ状態で左右に余白をつける場合に記述
          padding-left: 20px; //両橋見切れ状態で左右に余白をつける場合に記述
          background-color: #fff; //スライドエフェクトをフェードにする場合背景色の指定がないと前のスライドが透けて見える

          &-inner{
            position: relative;
            color:#fff;
            a{
                color: #fff;
                text-shadow: #333 2px 2px;
            }
            &-title{
                position: absolute;
                bottom:5%;
                left:5%;
                width:calc(100% - 10%);
                padding:10px 0;
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height:1px;
                    bottom:0;
                    left:0;
                    border-bottom:1px solid #fff;
                }
              }  
              &.is-sample{
                .note{
                  position: absolute;
                  top:0;
                  right:5px;
                  color:#fff;
                  font-size: 10px;
                }
                // &:before{
                //     content:"";
                //     position: absolute;
                //     top:0;
                //     right:5px;
                //     font-size:10px;
                // }
            

            }
          }
        //   .swiper-slide-inner1 {
        //     // background: red;
        //     width: 100%;
        //     height: 100%;
        //   }
   
        //   .swiper-slide-inner2 {
        //     // background: blue;
        //     width: 100%;
        //     height: 100%;
        //   }
   
        //   .swiper-slide-inner3 {
        //     // background: green;
        //     width: 100%;
        //     height: 100%;
        //   }
        }
      }
      /* ページネーション */
      .swiper-pagination {
        .swiper-pagination-bullet {
          background-color: #333; //ドットの色を変更したい場合
          border: 1px solid #fff; //ドットのボーダーを変更したい場合
          opacity: 1; //非アクティブなドットの不透明度を変更したい場合
          &-active {
            background: #53c638; //アクティブなドットの色を変更したい場合
          }
        }
      }
   
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 0%; //ドットの位置を変更したい場合
        left: 0;
      }
   
      /* スクロールバー */
      .swiper-scrollbar {
        opacity: 0; //表示する場合は1に
      }
   
      /* 前後ナビゲーション */
   
      //共通
      .swiper-button-next.swiper-button-disabled,
      .swiper-button-prev.swiper-button-disabled {
        opacity: 1; //非アクティブ時の不透明度を変更したい場合
      }
   
      //ナビゲーションサイズ
      .swiper-button-next:after,
      .swiper-button-prev:after {
        font-size: 30px; //ナビゲーションサイズを変更したい場合
      }
   
      //進む
      .swiper-button-next {
        opacity: 1; //非アクティブ時の不透明度を変更したい場合
        color: #333; //進むボタンの色を変更したい場合
        top:calc(100% - 30px); //進むボタンの位置を変更したい場合
        outline:none; //アウトラインを無効化
        left:calc(50% + 30px);
      }
      //戻る
      .swiper-button-prev {
        opacity: 1; //非アクティブ時の不透明度を変更したい場合
        color: #333; //戻るボタンの色を変更したい場合
        top:calc(100% - 30px); //進むボタンの位置を変更したい場合
        outline:none; //アウトラインを無効化
        left:calc(50% - 30px);
      }
    }
  }