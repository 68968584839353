
// フォント
$base_font_size: 1.6em;
$base_line_height: 1.6;
$base_letter_spacing: .05em;

// コンテンツ幅
$content_width: 1140px;

// ヘッダーの高さ
$header_height: 6rem;

// z-indexの管理
$z_index_nav_drawer: 1002;
$z_index_nav_header: 1000;
$z_index_photo_modal: 1002;

// カラーコード
//$color_main: #757575;

$color_black: #222222;
$color_grey: #aaaaaa;
$color_grey_light:#eeeeee;
$color_light: #fafafa;
$color_white: #ffffff;
$color_navy:#201BA0;
$color_navy_dark:#0C0E53;
$color_main:$color_navy;
$color_navy_light:#E0E1FF;//#E3ECFF;

