.works-item {
    $selector: #{&};
    &__inner{    
        // $selector: #{&};
        display: block;
        max-width: 600px;
        //height: 100%;
        margin: 0 auto;
        padding: 0;
        overflow: hidden;
        // border-radius: 0.8rem;
        // background: $color_white;
        color: $color_black;
        cursor: pointer;
        
    }

        // サムネイル部分
        &__thumbnail {
            aspect-ratio: 4/3;
      
            display: flex;
            justify-content: center;
            align-items: center;

            overflow: hidden;
            @include shadow("item");
            @include transition();
            // ホバー
            &:hover {
                @include shadow("item-hover");
                #{$selector}__thumbnail-image {
                    transform: scale(1.1);
                }
                #{$selector}__button-more {
                    background: $color_black;
                    color: $color_white;
                    &:after {
                        transform: translateX(4px);
                    }
                }
            }
    
        }
        &__thumbnail-image {
            
            @include transition();
            @include responsive_image();
            &.is-full-height{
                max-width: inherit;
                height:100%;
            }
        }
        // メイン部分
        &__content {
            padding:2rem 0;
            @media (min-width: 992px) {
                padding: 2rem 0;
            }
        }
        &__content-title {
            color: $color_black;
            font-weight: 600;
            font-size: 1.6rem;
            text-align: left;

        }

    &__summary{
        padding:10px 0;
        color:#333;
        font-size:1.6rem;
    }
}
