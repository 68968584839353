// breadcrumbs
.breadcrumbs {
    // container
    &__container {
        @include container("breadcrumbs");
    }
    // list
    &__list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    // list-item
    &__list-item {
        font-size: 1.6rem;
        &:not(:first-child){
            &::before{
                @include font_icon("arrow-right");
            }
        }
    }
    // link
    &__link {
        color: $color_black;
        &:hover,&:focus{
            text-decoration: underline;
        }
    }
}
