// about-me
.about-me {
    // container
    &__container {
        @include container("default");
    }
    // content
    &__content {
        @include responsive("pc") {
            display: flex;
        }
    }
    &__content-left {
        @include responsive("pc") {
            width: 50%;
            padding-right: 2.4rem;
            margin-bottom: 0;
        }
        margin-bottom: 4.8rem;
    }
    &__content-right {
        @include responsive("pc") {
            width: 50%;
        }
        display:flex;
        align-items: center;
    }
    // image
    &__image {
        img {
            display: block;
            max-width: 100%;
            border-radius: 1.6rem;
        }
    }
}
