// news-list
.news-list {
    // container
    &__container {
        @include container("default");
    }
    // list
    &__list {
        display: block;
        padding: 0;
        margin: 0 auto;
        max-width: 100rem;
    }
    &__list-item {
        display: block;
        margin:0;
        padding:0;
        &:not(:first-child){
            margin-top: 1.6rem;
            padding-top: 1.6rem;
            border-top: 1px solid #ddd;
        }
    }
    // pagination
    &__pagination {
        padding-top: 6.4rem;
    }
}
