// contact-form
.contact-form{
    // container
    &__container{
        @include container("default");
    }
    // heading
    &__heading {
        margin-bottom:3.2rem;
    }
    // content
    &__content{
        margin: 0 auto;
        padding: 3.2rem 1rem;
        background: #fff;
        border: 4px solid #efefef;
        @media (min-width: 992px) {
            padding: 6.4rem 4rem;
        }
    }
    // form
    &__form{
        max-width:850px;
        margin: 0 auto 1.6rem auto; 
        @media (min-width: 992px) {
            margin: 0 auto 6.4rem auto;
        }
    }
    // button
    &__button{
        text-align:center;
    }
    &__button-submit{
        @include button("default");
        background:$color_main;
        padding: 1em 3em;
        color:#fff;
        border-radius:50px;
        width:100%;
        max-width: 100%;
        @media (min-width: 992px) {
            max-width: 240px;
        }
        &:hover,&:focus{
            background: darken($color_main,5%);
        }
    }
}