.footer {
    background: $color_navy_dark;
    &__container {
        @include container("footer");
        // background-color: #0B0642;
        color:#fff;
        @include responsive("pc") {
            display: flex;
        }
    }
    &__info{
        font-size:2rem;
        @include responsive("pc") {
            flex-grow: 1;
            font-size:1.6rem;
        }
    }
    &__logo{
        padding:3rem;
        @include responsive("pc") {
            padding-left:0;
        }
        img{
            max-width: 220px;
            margin: 0 auto;
            @include responsive("pc") {
                margin:0;
            }
        }
    }
    &__address{
        text-align: center;
        @include responsive("pc") {
            text-align: left;
        }
    }
    &__menu {
        width:50%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        list-style: none;
        margin: 0 auto;
        padding: 4rem 0;
        @include responsive("pc") {
            flex-wrap: nowrap;
            margin:0;
            display: block;
            width:100%;
        }
    }
    &__menu-item {
        // &:not(:first-child) {
        //     margin-left: 1.6rem;
        // }
        width:50%;
        text-align: center;
        padding-bottom: 1.5rem;;
        @include responsive("pc") {
            width:auto;
            display: inline-block;
            padding-right:2rem;
        }
    }
    &__menu-item-link {
        color: $color_light;
        @include transition();
        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    &__contact{
        &__container{
            @include responsive("pc") {
                padding-top:2rem;
            }
        }
        text-align: center;
        border: 1px solid #fff;
        padding:2rem;
        margin: 2rem;
        @include responsive("pc") {
            // display: flex;
            align-items: center;
            

            p{
                display: inline-block;
            }
        }
        &-heading{
            padding-bottom:2rem;

              text-align: left;
            @include responsive("pc") {
                text-align: center;
                padding:0;
            }
            @include responsive("tablet") {
                text-align: center;
                
            }

        }
        .contact-guide__button{
            background-color: #fff;
            color:#0B0642;
            font-size:2rem;
            font-weight: bold;
            border: none;
            &:hover{
                color:#fff;
            }
            margin-bottom:2rem;
            background-image:none;
            @include responsive("pc") {
                background-color: inherit;
                color:inherit;
                display: inline-block;
                width: auto;
                margin:0;
                font-size:3.6rem;

                
            }
        }
    }

    &__copy {
        padding: 1.6rem 0;
        color: $color_white;
        font-size: 1.2rem;
        text-align: center;
        background-color: #0B0928;
    }
}