
.top-recruit {
    // container
    &__container {
        @include container("default");
        max-width: 100%;
        background-image: url('../img/image05.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        @include responsive("pc") {
            padding-left: 50%;
        }
    }
    // heading
    &__heading {
        margin-bottom: 2rem;
        color:#fff;
        font-size:2rem;
        @include responsive("pc") {

            font-size: 3rem;
        }
    }

    &__subtext{
        color:#fff;
        @include responsive("pc") {

        }
    }
   
    // guide
    &__guide {
        padding-top: 6.4rem;
        @include responsive("pc") {
            .button-guide{
                margin:0;
            }
        }
    }
}