.top-company{
    max-width: 1140px;
    margin: 0 auto;
    padding: 12.4rem 15px;

    width:100%;
    text-align: left;

    &__inner{
        // max-width: 1000px;
        // overflow: hidden;
        position: relative;
    }
    &__image{
        top:0;
   
        right:0;
        z-index: -1;
        overflow: hidden;
        aspect-ratio:5/4;
        width: 100%;
        @include responsive("pc") {
            position: absolute;
            max-width: 60%;
        }
    }
    &__copy{
        padding:60px 0 30px;
        font-size: 2.6rem;

    }
    &__subtext,
    &__button{
        padding:30px 20px 30px 0;
        @include responsive("pc") {
            max-width: 40%;
            padding:30px 20px 30px 0;
        }
    }
    &__button{
        // width: 100%;
        // display: flex;
        // justify-content: flex-start;
    }
}