// button-guide
.button-arrow {
    // @include button("block");
    // @include shadow("item");
    // display: flex;
    // width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    padding:1rem 1.6rem;
    border: none;
    border-radius: 8px;
    // background: $color_navy;
    color: $color_navy;
    font-weight: 400;
    font-size: 1.6rem;
    // &:hover, &:focus {
    //     background: $color_main;
    //     color: #ffffff;
    // }
        position: relative;
    &::after{
        position: absolute;
        bottom:0;
        left:1rem;
        content: '';
        width: 100%;
        height: 15px;
        border-bottom:$color_navy solid 1px;
        border-right:$color_navy solid 1px;
        transform: skew(45deg);
    }    
}