// company-overview 
.company-overview {
    // container
    &__container {
        @include container("default");
    }
    // heading
    &__heading {
        margin-bottom: 6.4rem;
    }
    // content
    &__content {
        @include responsive("pc") {
            display: flex;
        }
        margin-bottom:4rem;
    }
    &__content-image {
        @include responsive("pc") {
            width: 50%;
        }
        img{
            // border-radius: 1.6rem;
            max-height:70vmax;
            margin:0 auto;
            @include responsive("pc") {
                max-height:100%;
                
            }
        }
    }
    &__content-table {
        @include responsive("pc") {
            width: 50%;
            padding: 0 0 0 3.2rem;
        }
        padding: 3.2rem 0 0 0;
    }
    // guide
    &__guide {
        padding-top: 6.4rem;
    }
}


// company-overview 
.recruit-overview {
    // container
    &__container {
        @include container("default");
    }
    // heading
    &__heading {
        margin-bottom: 6.4rem;
    }
    // content
    &__content {
        @include responsive("pc") {
            display: flex;
        }
        margin-bottom:4rem;
    }
    &__content-image {
        @include responsive("pc") {
            width: 50%;
        }
        img{
            // border-radius: 1.6rem;
            max-height:70vmax;
            margin:0 auto;
            @include responsive("pc") {
                max-height:100%;
                
            }
        }
    }
    &__content-table {
        @include responsive("pc") {
            width: 50%;
            padding: 0 0 0 3.2rem;
        }
        padding: 3.2rem 0 0 0;
    }
    // guide
    &__guide {
        padding-top: 6.4rem;
    }
}

.recruit-content{
    &__inner{

    }
    &__flow{
        background-color: $color_navy_light;
        margin-bottom: calc(3rem + 40px);
        position: relative;
        padding:2rem;
        @include responsive("pc") {
        display: flex;
        align-items: center;

        }
        &::after{
            content: "";
            display: block;
            background-image: url('../img/icon-down.svg');
            width:60px;
            height:30px;
            background-size: contain;
            background-repeat: no-repeat;
            bottom:-50px;
            position: absolute;
            left:50%;
            transform: translateX(-50%);
        }

        h4{
            color:$color_grey;
            font-size: 4rem;
            font-weight: normal;
            
            &::before{
                content:"Flow ";
                font-size:2rem;
            }

            @include responsive("pc") {
                align-self: flex-start;
            }
        }

        h3{
            @include responsive("pc") {
            border-left:1px solid $color_grey;
            padding-left: 1rem;
            padding-right :1rem;
            margin-left:1rem;
            margin-right:1rem;
            padding-bottom: 0;
            min-width: 15rem;
            }
            padding-bottom:1rem;
      
            
            color:$color_navy;

            font-size: 2rem;
        }

        &--end{
            border: 3px solid $color_navy;
            padding:2.5rem;
            text-align: center;
            font-size:3rem;
            color:$color_navy;
            font-weight: bold;
        }
    }

}

table{
    &.is-border{
        border-spacing: 0;
        tr{
            th,td{
                @include responsive("tablet") {
                border-bottom:1px solid $color_grey;
                }
                padding: 2rem 0;
                
            }

        }
    }
}