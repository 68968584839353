// service-content
.recruit-content,
.service-content {
    // container
    &__container{
        @include container("default");
    }
    // heading
    &__heading {
        margin-bottom:6.4rem;
    }
    // list
    &__list {
        // max-width: 100rem;
        margin:0 auto;
    }
    &__list-item {
        margin-bottom: 4.8rem;
        @include responsive("pc") {
            figure{
                padding-left:0;
                padding-right:4rem;
            }
            &:nth-of-type(odd){
                >*{
                    flex-direction: row-reverse;
                    figure{
                        padding-left:4rem;
                        padding-right:0;
                    }
                }
            }
        }
    }
    // guide
    &__guide {
        margin-top:6.4rem;
    }
}
