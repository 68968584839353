.page-heading-sub {
    &__content {
        margin: 0;
        padding: 0;
        &--left{
            text-align:left;
        }
        &--center{
            text-align:center;
        }
        &--right{
            text-align:right;
        }
    }
    // メインテキスト
    &__text {
        position: relative;
        font-size: 2.4rem;
        &:before {
            display: inline-block;
            vertical-align: middle;
            width: 6px;
            height: 1.6em;
            margin-right:.8rem;
            border-radius: 3px;
            background: $color_main;
            content: "";
        }
    }
    &__message {
        margin: 0 auto;
        padding-top: 1.6rem;
        color: $color_grey;
        font-weight: 400;
        font-size: 1.6rem;
    }
}
