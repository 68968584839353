// table-overview
.table-overview {
    width: 100%;
    line-height: 1.8;
    text-align: left;
    tr {
        th {
            width: 15rem;
            padding: .8rem;
            
            color:$color_navy;
            display: block;
            @include responsive("tablet") {
                vertical-align: top;
                display: table-cell;
            }
        }
        td {
            
            padding: .8rem 1.6rem;
            @include responsive("tablet") {
                width: calc(100% - 15rem);
                vertical-align: top;
                display: table-cell;
            }
            display: block;
        }
    }
}
