// works-detail
.works-detail {
    // container
    &__container {
        @include container("default");
    }
    // image
    &__image {
        @extend %__image;
        %__image {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
        &--main {
            margin-bottom: 4rem;
        }
        &--sub {
            margin-bottom: 1.6rem;
        }
    }
    // list
    &__list {
        list-style: none;
        margin: 0 -15px;
        padding: 0;
        @media (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__list-item {
        margin: 0 15px;
        margin-bottom: 4rem;
        @media (min-width: 992px) {
            width: calc(100% / 2 - 30px);
        }
    }
    // text
    &__text-main {
        margin-bottom: 4rem;
        color: $color_grey;
    }
    &__text-sub {
        margin: 0;
        color: $color_grey;
        font-size: 1.4rem;
    }
    // button
    &__button{
        @include responsive("pc"){
            padding-top:6.4rem;
        }
        padding-top: 3.2rem;
    }
}
