/*
 * HTML
 * -------------------------------------------------------------------
 */
html {
    font-size: 62.5%;
}
body {
    font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3",
        Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base_font_size;
    line-height: $base_line_height;
    letter-spacing: $base_letter_spacing;
}
a {
    text-decoration: none;
    outline: none;
}
/*
 * wrapper
 * -------------------------------------------------------------------
 */
.wrapper {
    display: block;
    margin: 0px auto;
    padding: 0px;
    min-height: 100vh;
    > section:nth-child(even) {
        background: #fafafa;
    }
}