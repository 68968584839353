@charset "utf-8";

@import "setting/variables";
@import "base/base";
@import "base/reset";
@import "base/animation";
@import "base/wordpress";


/*
 * Mixin
 * -------------------------------------------------------------------
 */
// button
@import "mixin/button";
// container
@import "mixin/container";
// font design
@import "mixin/font_design";
// font icon
@import "mixin/font_icon";
// responsive image
@import "mixin/responsive_image";
// responsive
@import "mixin/responsive";
// shadow
@import "mixin/shadow";
// transition
@import "mixin/transition";
// visually hidden
@import "mixin/visually_hidden";

/*
 * Module
 * -------------------------------------------------------------------
 */
// ナビゲーション
@import "module/nav-header";
@import "module/nav-drawer";
@import "module/nav-pagetop";
// メインビジュアル
@import "module/mainvisual";
// ページビジュアル
@import "module/pagevisual";
// パンくずリスト
@import "module/breadcrumbs";
// 見出し
@import "module/page-heading";
@import "module/page-heading-sub";
@import "module/page-block";
// フッター
@import "module/footer";
// 画像モーダル
@import "module/photo-modal";
// ボタン
@import "module/button-guide";
@import "module/button-detail";
@import "module/button-arrow";
// ページネーション
@import "module/pagination";
// フォーム
@import "module/form";
// テーブル
@import "module/table-overview";
// 新着情報
@import "module/news-item";
@import "module/news-content";
// サービス
@import "module/service-feature-item";
@import "module/service-content-item";
// 制作実績
@import "module/works-item";





/*
 * Fonts
 * -------------------------------------------------------------------
 */
// Noto Sans JP(400,700)
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
// Comfortaa(500)
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');
// Material Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/*
 * トップページ
 * -------------------------------------------------------------------
 */
// トップページ - メッセージ
@import "page/top-message";
// トップページ - サービス
@import "page/top-service";
// トップページ - 制作実績
@import "page/top-works";
@import "page/top-works-slider";
// トップページ - 新着情報
@import "page/top-news";
// トップページ - ブログ
@import "page/top-blog";
@import "page/top-company";

@import "page/top-recruit";


/*
 * 新着情報
 * -------------------------------------------------------------------
 */
// 新着情報一覧
@import "page/news-list";
// 新着情報詳細
@import "page/news-detail";

/*
 * サービス紹介
 * -------------------------------------------------------------------
 */
// サービス紹介 - 特徴
@import "page/service-feature";
// サービス紹介 - 内容
@import "page/service-content";

/*
 * 制作実績
 * -------------------------------------------------------------------
 */
// 制作実績一覧
@import "page/works-list";  
// 制作実績詳細
@import "page/works-detail";

/*
 * 会社情報
 * -------------------------------------------------------------------
 */
// 会社情報 - 自己紹介
@import "page/about-me";
// 会社情報 - 会社概要
@import "page/company-overview";
// 会社情報 - info
@import "page/about-info";

/*
 * CONTACT
 * -------------------------------------------------------------------
 */
// 問い合わせ - CTA
@import "page/contact-guide";
@import "page/contact-form";



//一時的
.works-list .works-item__thumbnail {
    display: none;
  }
  .works-list {
    // list
    &__list {
        margin: 0 -15px 0 -15px;
        @media (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__list-item {
        margin: 15px 15px 4.8rem 15px;
        border-bottom:1px dashed #ccc;
        @media (min-width: 992px) {
            width: 80%;//calc(100% / 3 - 30px);
            margin: 15px auto;
        }
    }
}