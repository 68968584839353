// button-guide
.button-guide {
    @include button("block");
    @include shadow("item");
    display: flex;
    // width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    padding:2rem 1.6rem;
    border: none;
    border-radius: 8px;
    background: $color_navy;
    color: $color_white;
    font-weight: 400;
    font-size: 1.6rem;

    &:hover, &:focus {
        
        color: #ffffff;
    }
        background-image: linear-gradient(90deg, rgba(42, 45, 172, 1), rgba(81, 134, 237, 1));


}