.mainvisual {
    position: relative;
    // 背景画像
    background-image: url("../img/work02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // 背景画像に半透明のレイヤーを被せる
    &::before {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-color: rgba($color_black, 0.3);
        content: "";
    }
    // container
    &__container {
        @include container("mainvisual");
        position: relative;
        z-index: 1;
    }
    // text
    &__text-main {
        @include font_design();
        @include responsive("pc") {
            font-size: 4.5rem;
        }
        font-weight: 600;
        font-size: 3.0rem;
        line-height: 1;
        color:$color_white;
    }
    &__text-sub {
        padding-top: 1.6rem;
        color: $color_white;
        font-size: 1.6rem;
    }
}
