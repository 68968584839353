// service-content-item
.service-content-item {
    @include responsive("pc") {
        display: flex;
    }
    &.is-reverse{
        flex-direction: row-reverse;
    }
    // サムネイル
    &__thumbnail {
        @include responsive("pc") {
            width: 70rem;
        }
    }
    &__thumbnail-image {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        // border-radius: .8rem;
    }
    // 内容
    &__content {
        @include responsive("pc") {
            width: calc(100% - 30rem);
            padding: 0 0 3.2rem;
        }
        padding:1.6rem;
    }
    &__content-title {
        @include responsive("pc") {
            font-size: 2.4rem;
            text-align: left;
        }
        color:$color_navy;
        margin-bottom: 1.6rem;
        font-weight: 700;
        font-size: 2rem;
        text-align: center;

        //下線
        &::after{
            
            content: "";
            display: block;
            border-bottom:2px solid $color_navy;
            width: 20%;
            margin: 0 auto;
            padding-top:1rem;
            @include responsive("pc") {
                padding-top:2rem;
                width:30px;
                margin:0;
            }

        }
    }
    &__content-subtitle {
        margin-bottom:2rem;
        font-weight: bold;
    }
    &__content-text {
        color: $color_grey;
    }
}
