// contact-guide
.contact-guide {
    padding: 12.4rem 15px;
    // container
    &__container {
        @include container("contact-guide");
    }
    // heading
    &__heading {
        margin: 0 0 4.8rem 0;
        text-align: center;
    }
    &__heading-main {
        @include font_design();
        font-size: 4.8rem;
        &:after {
            display: block;
            max-width: 300px;
            margin: 1.6rem auto 1.6rem auto;
            border-bottom: 3px solid #222222;
            content: "";
        }
    }
    &__heading-sub {
        font-weight: 400;
        font-size: 1.4rem;
    }
    // message
    &__message {
        margin: 0 0 4.8rem 0;
        color: $color_grey;
        font-size: 1.6rem;
        text-align: center;
    }
    &__list-item {
        margin: 0 0 2.4rem 0;
        text-align: center;
    }
    // button
    &__button {
        @include button();
        width: 100%;
        max-width: 600px;
        padding: 1.6rem;
        // border: 1px solid $color_main;
        border-radius: 50px;
        // background: $color_main;
        background-image: linear-gradient(90deg, rgba(42, 45, 172, 1), rgba(81, 134, 237, 1));
     
        color: #ffffff;
        &:hover {
            background: darken($color_main, 5%);
        }
      
    }
}
