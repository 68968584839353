// responsive
@mixin responsive($type: "pc") {
    @if $type == "tablet" {
        @media screen and (min-width: 600px) {
            @content;
        }
    }
    @if $type == "pc" {
        @media screen and (min-width: 992px) {
            @content;
        }
    }
}
